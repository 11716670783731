import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  static propTypes = {
    title: PropTypes.string,
  }

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <div>
      {/* <div>
        <div className="footer-item">
          <p className="title">联系我们</p>
          <ul className="list" style={{listStyle:'none'}}>
            <li className="item"><a href="mailto:bd@zhongqishuzi.com" className="email">商业合作:  bd@zhongqishuzi.com</a></li>
            <li className="item"><a href="mailto:hr@zhongqishuzi.com" className="email">人才招聘:  hr@zhongqishuzi.com</a></li>
            <li className="item"><a href="mailto:press@zhongqishuzi.com" className="email">媒体:  press@zhongqishuzi.com</a></li>
            <li className="item"><a href="mailto:info@zhongqishuzi.com" className="email">其他:  info@zhongqishuzi.com</a></li></ul></div>
      </div> */}
      <div className="footer">
          <div className="footer-copyright center" style={{ margin: "0px" }}>Copyright © 2010-2019 BeiJing ZhongQiShuZi Technology Co., Ltd. All rights reserved.
          京ICP备16033604号-1</div>
          <div className="footer-copyright-m center">
            <div>Copyright © 2010-2019</div>
            <div>BeiJing ZhongQiShuZi Technology Co., Ltd. All rights reserved.</div>
            <div>京ICP备16033604号-1</div>
          </div>
          <div style={{ width: '300px', margin: '0 auto', padding: '0 0 16px', 'textAlign': 'center' }}>
            <img
              src={require("../../resource/beian.png")} alt="" /><a target="_blank"
                href="./"
                style={{ display: 'inline-block', 'textDecoration': 'none', height: '20px', 'lineHeight': '20px' }} >
              <Link to="./" className="text-link">
                <p style={{ float: 'left', height: '20px', 'lineHeight': '20px', margin: '0px 0px 0px 5px', color: '#999', 'fontSize': '12px' }}>京公网安备
              33010802010173号</p></Link>
            </a>
          </div>
        </div>
        </div>
    );
  }
}

export default Footer;