export default {
  "id": "5c1b78652eb328001b7c29d9",
  "modules": [
    {
      "head": {
        "title": "可信身份服务",
        "description": "基于区块链系统的分布式身份标识 ( Decentralized Identifiers，DIDs )，具有自主可控、分布式信任、隐私保护等特点。用户可以自己管理隐私信息，并将其可控的出示给相关第三方。 通过分布式身份标识，可以构建可靠的分布式信任网络，在降低信任成本的同时，有效保障用户个人隐私。",
        "category": "技术",
        "introduction": "基于区块链系统的分布式身份标识 ( Decentralized Identifiers，DIDs )，具有自主可控、分布式信任、隐私保护等特点。用户可以自己管理隐私信息，并将其可控的出示给相关第三方。 通过分布式身份标识，可以构建可靠的分布式信任网络，在降低信任成本的同时，有效保障用户个人隐私。",
        "buttons": [
          {
            "label": "联系我们",
            "link": "https://baas.zhigui.com/application",
            "needLogin": false,
            "style": "primary"
          }
        ],
        "image": "resource/banner44.svg"
      }
    },
    // {
    //   "productionTab": {
    //     "title": "方案介绍",
    //     "subtitle": "",
    //     "background": "#ffffff",
    //     "pattern": "horizontal",
    //     "image": "resource/DIDCN-1545303503866.svg",
    //     "items": [
    //       {
    //         "title": "保障数据隐私的可验证声明",
    //         "description": "在某些场景中，用户并不愿意将所有信息公开，分布式身份标识技术提供了一种规范来描述并声明实体所具有的某些属性，既可以放在链上也可以根据用户的隐私性要求存储在链下"
    //       },
    //       {
    //         "title": "可验证的数字身份形式",
    //         "description": "具有去中心化、自主管理、安全、可验证等特点。通过分布式的身份认证以及声明实体验证签名，提升作假成本，提升声明数据的可信度。"
    //       }
    //     ]
    //   }
    // },
    {
      "image-text": {
        "title": "应用场景",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "简历验证",
            "description": "企业对面试者简历内容的验证，每条简历内容可关联一条可验证声明，由相关权威认证机构签发，保证简历内容的真实准确。",
            "icon": "resource/简历验证.svg"
          },
          {
            "title": "食品溯源",
            "description": "针对农产品生产、代理和销售每个环节涉及的数据，都可以通过分布式身份标识和可验证声明的方式发布、背书、验证和查询。",
            "icon": "resource/食品溯源.svg"
          },
          {
            "title": "身份认证",
            "description": "需要审核用户的材料是否符合标准，用户的相关属性可通过签发可验证声明来实现，并将其放到链上等待验证，保证了相关信息的真实不可篡改。",
            "icon": "resource/身份认证.svg"
          }
        ],
        "category": "no-border",
        "column": 3
      }
    },
    {
      "tab": {
        "title": "框架图",
        "subtitle": "",
        "background": "#ffffff",
        "items": [
          {
            "label": "",
            "button": {
              "label": "联系我们",
              "link": "https://baas.zhigui.com/application",
              "needLogin": false,
              "style": "primary"
            },
            "frameDiagram": "resource/分布式身份标识_cn-1545706581815.svg",
            "items": [
              {
                "title": "",
                "description": "分布式身份标识解决方案的系统由基础设施层、区块链层、服务层、业务层、运维管理五个子系统构成。通过区块链层完成DID身份的生成，并进行可验证声明的创建、签名、持有和验证。可验证声明可以存在链上，也可以在其他链上、甚至非链系统中，通过跨链互操作、预言机等方式与区块链交互。"
              }
            ]
          }
        ]
      }
    },
    {
      "image-text": {
        "title": "方案优势",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "隐私保护",
            "description": "针对用户的可验证声明数据，采用零知识证明，验证者可在部分数据被用户隐藏的情况下，准确判断声明内容是否正确。",
            "icon": "resource/身份认证.svg"
          },
          {
            "title": "可信声明",
            "description": "对用户的DID增加信任值属性，并可通过用户对可验证声明的签名，对用户信任值进行一定标准的奖惩，从而规范用户行为，达到提高可验证声明可信度的目的。",
            "icon": "resource/身份认证.svg"
          },
          {
            "title": "高效状态维护",
            "description": "针对数据状态的维护，通过签发撤销类型的可验证声明，并与原声明相关联，保证声明的“是否已撤销“状态有效维护。",
            "icon": "resource/身份认证.svg"
          }
        ],
        "category": "card",
        "column": 3
      }
    }
  ],
  "order": 10,
  "createTime": "2018-12-20T11:09:25.254Z",
  "updateTime": "2019-06-20T09:10:07.405Z"
}