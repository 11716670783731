import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

class HomePage extends React.Component {
  static propTypes = {
    title: PropTypes.string,
  }

  render() {
    return (
      <div>
        <div className="section-home solution-banner">
          <img className="solution-banner-image" style={{ bottom: '0px' }} src={require("../../resource/banner11.png")}></img>
          <div>
            <h1>企业级BaaS服务平台</h1>
            <p>帮助企业解决快速构建区块链项目，提供溯源、存证等功能。</p>
          </div>
        </div>

        <div className="section-home">
          <div className="home-section-features">
            <h1 className="center">技术应用方向</h1>
            <p className="center"></p>

            <div className="pure-g home-features">
              <div className="pure-u-1-1 pure-u-md-1-2 pure-u-lg-1-4"
                style={{ animation: '0.5s ease 0s 1 normal both running slide-top', opacity: '1' }}><img
                  src={require("../../resource/home_icon_1@3x.png")} alt="" />
                <div>
                  <h3>互信金融</h3>
                  <p>可信数据采集、多级信用传递、大数据联合风控、便捷融资通道</p>
                </div>
              </div>
              <div className="pure-u-1-1 pure-u-md-1-2 pure-u-lg-1-4"
                style={{ animation: '0.5s ease 0s 1 normal both running slide-top', opacity: '1' }}><img
                  src={require("../../resource/home_icon_2@3x.png")} alt="" />
                <div>
                  <h3>互信数据</h3>
                  <p>跨主体数据协作、数据隐私保护、安全多方计算、可追溯可监管</p>
                </div>
              </div>
              <div className="pure-u-1-1 pure-u-md-1-2 pure-u-lg-1-4"
                style={{ animation: '0.5s ease 0s 1 normal both running slide-top', opacity: '1' }}><img
                  src={require("../../resource/home_icon_3@3x.png")} alt="" />
                <div>
                  <h3>互信版权</h3>
                  <p>全类型内容存证、经济高效、权威节点认证、一站式服务</p>
                </div>
              </div>
              <div className="pure-u-1-1 pure-u-md-1-2 pure-u-lg-1-4"
                style={{ animation: '0.5s ease 0s 1 normal both running slide-top', opacity: '1' }}><img
                  src={require("../../resource/home_icon_4@3x.png")} alt="" />
                <div>
                  <h3>互信溯源</h3>
                  <p>全链条溯源、一物一码、立体化品牌升级、区块链防篡改</p>
                </div>
              </div>
            </div>

          </div>
          <div className="break-line"></div>
        </div>

        <div className="section-home">
          <div className="home-section-system">
            <div className="home-section-system-image">
              <img src={require("../../resource/banner55.png")} alt="" />
            </div>
            <div className="home-section-system-desc">
              <h1>中企数字BaaS</h1>
              <p>·管理控制平台 - 智能硬件采购、SKU信息管理、流转节点<br />·业务平台 - 商品赋码管理、大小码集合管理、流转节点信息采集<br />·消费互动 - 商品验真、商品追溯、商品上链</p>

              <div className="item">
                <p>业务痛点与挑战：<br />用户私钥难保管、安全服务要求高、数据隐私难保障</p>
              </div>

              <div className="item">
                <p>业务场景：<br />授权访问控制、高并发业务需求、用户身份认证</p>
              </div>
            </div>
          </div>
        </div>

        <div className="section-home" style={{ 'backgroundColor': '#f5f6f8' }}>
          <div className="home-section-service">
            <h3 className="center">提供的服务</h3>
            <p> </p>
            <div className="pure-g home-services">
              <div className="pure-u-1-2 tns-vertical-style">
                <div className="home-card">
                  <div className="home-card-image" style={{ background: 'linear-gradient(45deg, #881AE3, rgba(80,11,194,0.6))' }}><img src={require("../../resource/banner22.png")} /></div>
                  <div className="home-card-content">
                    <div className="home-card-content-main">
                      <h4>数据存证</h4>
                      <p>通过容器服务构建区块链底层，融合DID、Oracle、分布式存储等模块化的底层技术，为上层应用提供存证、监测、取证、公证和交易等功能支持，以覆盖更广泛的业务场景</p>
                    </div>
                    <div className="home-card-content-extra">
                      <h4>服务内容</h4>
                      <ul>
                        <li>支持多种数据存证</li>
                        <li>数据链上登记</li>
                        <li>存证证书</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pure-u-1-2 tns-vertical-style">
                <div className="home-card">
                  <div className="home-card-image" style={{ background: 'linear-gradient(45deg, #881AE3, rgba(80,11,194,0.4))' }}><img src={require("../../resource/banner33.png")} alt='' /></div>
                  <div className="home-card-content">
                    <div className="home-card-content-main">
                      <h4>品质溯源</h4>
                      <p>基于区块链的全程化的溯源追踪管理体系，将产品从原材料的选取到最后被消费者购买的整个环节信息都上链，实现商品信息的实时溯源和不可篡改，保障消费者的购买权益。</p>
                    </div>
                    <div className="home-card-content-extra">
                      <h4>服务内容</h4>
                      <ul>
                        <li>强化供应链管理</li>
                        <li>保障消费安全</li>
                        <li>共建产品溯源示范区</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-home">
          <div className="home-section-users">
            <h2 className="center">我们的客户</h2>
            <p className="center">在区块链行业的探索中，我们与他们一起协力同行，用新技术创造不可估量的价值！</p>
            <div style={{ display: "none" }}><img src={require("../../resource/logo.svg")} alt=""
              style={{ width: "121px", height: "75px" }} /></div>
            <div className="home-users">
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/国家质量监督检验检疫总局.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/中华人民共和国公安部.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/国家和卫生计划生育委员会.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/中国防伪行业协会.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/故宫博物院.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/中国烟草.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/上海大众.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/方正集团.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/小米.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/京东.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/佛山照明.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/PLAYBOY.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/新奥集团.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/远通信德.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/中云路达.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/招商银行.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/平安科技集团.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/中国工商银行.png")} /></div>
              <div className="home-user"><img className="pure-img" alt="" src={require("../../resource/ads/中国邮政储蓄银行.png")} /></div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default HomePage;