import React from 'react';
import PropTypes from 'prop-types'

const products = [
  {
    "title":"中企数字Baas",
    "icon":"resource/product_one.png",
    "icon_m":"resource/m1.png",
    "description":"基于区块链技术为企业级用户提供以BaaS服务， 包括:资产管理、追踪溯源、防伪校验、新型供应链管理等",
    "id": "about-1",
  },
  {
    "title":"数据存证",
    "icon":"resource/product_two.png",
    "icon_m":"resource/m2.png",
    "description":"在底层资源的基础上，通过容器服务构建区块链底层，融合DID、Oracle、分布式存储等模块化的底层技术，为上层应用提供存证、监测、取证、公证和交易等功能支持，以覆盖更广泛的业务场景",
    "id": "about-2",
  },
  {
    "title":"品质溯源",
    "icon":"resource/product_three.png",
    "icon_m":"resource/m3.png",
    "description":"基于区块链的全程化的溯源追踪管理体系，将产品从原材料的选取到最后被消费者购买的整个环节信息都上链，实现商品信息的实时溯源和不可篡改，保障消费者的购买权益",
    "id": "about-3",
  }
];

class AboutPage extends React.Component {
  static propTypes = {
    title: PropTypes.string,
  }

  imageObject = (src) => {
    // react坑：该函数只能写在component，封装成工具类会报错；并且require的入参只能写'../xx'，不能用变量带入
    if (src.indexOf('resource') !== -1) {
      return require('../../' + src);
    }
    else if (src.indexOf('http') !== -1) {
      return src;
    }
  
    return require(src);
  }

  render() {
    return (<div>
      <div className="section-home solution-banner">
        <img className="solution-banner-image" style={{bottom: '0px'}} src={this.imageObject('resource/banner11.png')}></img>
        <div>
          <h1>政府服务，企业赋能</h1>
          <p>以区块链技术创新为突破口加快推动区块链技术和产业创新发展</p>
        </div>
      </div>

      <div className="section-about">
        <div className="about-section-introduce">
          <h2>公司简介</h2>
          <p className="pre-wrap">
            中企数字企业级区块链技术解决方案提供方。基于区块链技术为企业级用户提供以BaaS服务，包括:资产管理、追踪溯源、防伪校验、新型供应 链管理等。
        旨在建立互信透明、信息高效协同、价值可信传输的商业生态。为用户提供可靠、安全、专 业的区块链技术顶层架构、配套建设、硬件结合的企业级解决方案，以区块链技术创新为突破口加快推 动区块链技术和产业创新发展 。</p>
          <div className="break-line"></div>
        </div>
      </div>

      <div className="section-about">
        <div className="about-section-introduce">
          <h2>团队联系方式</h2>
          <p className="pre-wrap">
            商业合作：BD@zhongqishuzi.com</p>
          <div className="break-line"></div>
        </div>
      </div>

      <div className="section-about">
        <div className="about-section-products">
          <h2>核心产品</h2>
          <div className="core-products-container">
            {products.map((item, idx) => {
              return (
                <div className={idx === 0 ? 'core-product-item highlight' : 'core-product-item'} data-height="276" key={item.id}>
                  <div className="icon"><i></i></div>
                  <div className="product-title">{item.title}</div>
                  <div className="product-des">{item.description}</div>
                  <div className="product-img"> <img src={this.imageObject(item.icon)} style={{ height: '255px' }} alt="" /></div>
                  <div className="product-bg"></div>
                </div>
              );
            })}
          </div>

          <div className="core-products-container-m">
          {products.map((item, idx) => {
              return (
                <div className="core-product-item-m" key={item.id}>
                  <div className="product-img"> <img src={this.imageObject(item.icon_m)} style={{ width: '100%' }} alt="" /></div>
                  <div className="product-title">{item.title}</div>
                  <div className="product-des">{item.description}</div>
                </div>
              );
            })}
          </div>

          <div className="break-line"> </div>
        </div>
      </div>
    </div>);
  }
}

export default AboutPage;