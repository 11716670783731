import React from 'react';
import PropTypes from 'prop-types'

class SolutionPage extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.object,
  }

  renderHead(head) {
    return (
      <div className="section-solution solution-banner">
        <img className="solution-banner-image" src={this.imageObject(head.image)} alt=""></img>
        <div>
          <h1>{head.title}</h1>
          <p>{head.introduction}</p>
        </div>
      </div>
    );
  }

  imageObject(src) {
    if (src.indexOf('resource') !== -1) {
      return require('../../' + src);
    }
    else if (src.indexOf('http') !== -1) {
      return src;
    }

    return require(src);
  }

  renderProductionTab(productionTab) {
    if (productionTab.pattern === 'vertical') {
      return (
        <div className="section-home">
          <div className="home-section-features">
            <h1 className="center">{productionTab.title}</h1>
            <p></p>
            <div class="production-productionTab-vertical">
              <div class="production-productionTab-horizontal-img">
                <img src={this.imageObject(productionTab.image)} alt="" />
              </div>
              <ul>
                {productionTab.items.map((item) => {
                  return (
                    <li class="production-productionTab-vertical-item" style={{listStyle:'none'}}>
                      {/* <h1><span></span>{item.title}</h1> */}
                      <p>{item.description}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="section-home">
        <div className="home-section-features">
          <h1 className="center">{productionTab.title}</h1>
          <p></p>
          <div class="production-productionTab-horizontal">
            <ul>
              {productionTab.items.map((item) => {
                return (
                  <li class="production-productionTab-horizontal-item">
                    <h2><span></span>{item.title}</h2>
                    <p>{item.description}</p>
                  </li>
                );
              })}
            </ul>
            <div class="production-productionTab-horizontal-img">
              <img src={this.imageObject(productionTab.image)} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFeatures(features, cardExtraStyle = {}) {
    const cardStyle = { animation: "0.5s ease 0s 1 normal both running slide-top", opacity: 1, ...cardExtraStyle };
    const pureClass = `pure-u-1 pure-u-md-1-2 pure-u-lg-1-${features.items.length}`

    return (
      <div className="section-home" style={{background: features.background}}>
        <div className="home-section-features">
          <h1 className="center">{features.title}</h1>
          <p></p>
          <div className="home-features">
            {
              features.items.map((item) => {
                return (
                  <div className={pureClass} style={cardStyle}>
                    <img src={this.imageObject(item.icon)} alt="" />
                    <div>
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }

  renderNiubility(features, cardExtraStyle = {}) {
    const cardStyle = { animation: "0.5s ease 0s 1 normal both running slide-top", opacity: 1, ...cardExtraStyle };
    const pureClass = `pure-u-1 pure-u-md-1-2 pure-u-lg-1-${features.items.length}`

    return (
      <div className="section-home" style={{background: features.background}}>
        <div className="home-section-features">
          <h1 className="center">{features.title}</h1>
          <p></p>
          <div className="home-niubility">
            {
              features.items.map((item) => {
                return (
                  <div className={pureClass}>
                    <div className="niubility-card" style={cardStyle}>
                      <img src={this.imageObject(item.icon)} alt="" />
                      <div>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }

  renderArchitect(architect) {
    return (
      <div class="section-solution">
        <div class="solution-section-logical fn-normal-padding">
          <h2 class="center">{architect.title}</h2>          

        <div class="production-tab-one-part">
          <div class="production-tab-one">
            <img src={this.imageObject(architect.items[0].frameDiagram)} alt="" />
              <div class="production-tab-one-right">
                <ul><li style={{listStyle:'none'}}>
                  <p>{architect.items[0].items && architect.items[0].items[0].description}</p>
                </li>
                </ul>
                {/* <button type="button" class="ant-btn production-tab-one-btn ant-btn-default">
                  <span>联系我们</span>
                </button> */}
              </div>
          </div>
        </div>
        </div>
      </div>
    );
  }

  render() {
    console.log(this.props);
    let head, productionTab, features, architect, niubility;
    this.props.data.modules.map((item) => {
      if (item.head) {
        head = item.head;
      } else if (item.productionTab) {
        productionTab = item.productionTab;
      } else if (item['image-text']) {
        if (!features) {
          features = item['image-text'];
        } else if (!niubility) {
          niubility = item['image-text'];
        } 
      } else if (item.tab) {
        architect = item.tab;
      }
    });

    return (
      <div>
        {head && this.renderHead(head)}
        {productionTab && this.renderProductionTab(productionTab)}
        {features && this.renderFeatures(features)}
        {architect && this.renderArchitect(architect)}
        {/* {niubility && this.renderNiubility(niubility, { background: '#ffffff', padding: '24px'})} */}
        <div className="break-line"></div>
      </div>
    );
  }
}

export default SolutionPage;