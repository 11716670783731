export default {
  "id": "5c1b7f8b2eb328001b7c29f5",
  "modules": [
    {
      "head": {
        "title": "保密安全服务",
        "description": "提供区块链上的密码学和安全方面的高级服务，如私钥安全存储、硬件安全模块、国密支持、数据隐私保护等，以满足不同用户对于系统机密性、安全性和系统性能的需求。",
        "category": "技术",
        "introduction": "提供区块链上的密码学和安全方面的高级服务，如私钥安全存储、硬件安全模块、国密支持、数据隐私保护等，以满足不同用户对于系统机密性、安全性和系统性能的需求。",
        "buttons": [
          {
            "label": "联系我们",
            "link": "https://baas.zhigui.com/application",
            "needLogin": false,
            "style": "primary"
          }
        ],
        "image": "resource/banner44.svg"
      }
    },
    {
      "image-text": {
        "title": "应用场景",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "应收账款融资",
            "description": "通过硬件设备存储私钥，可以保证网络身份与链下实体身份的一一对应。所有身份信息与授权规则保存于链上，安全透明、不可篡改。",
            "icon": "resource/应收账款融资.svg"
          },
          {
            "title": "金融票据流转",
            "description": "通过硬件加密设备，有效提升密码学服务速度，突破区块链系统性能瓶颈，提升交易吞吐量。",
            "icon": "resource/金融票据流转.svg"
          },
          {
            "title": "应付账款融资",
            "description": "在需要用户身份认证的场景中，通过分布式身份标示和零知识证明技术，确保身份可信确认的同时，切实保障用户数据隐私。",
            "icon": "resource/应付账款融资.svg"
          },
          {
            "title": "实物融资",
            "description": "客户涉及到银行、保理、监管等多参与方，采用国密算法实现密码学及安全服务是对信息系统的基本要求。",
            "icon": "resource/实物融资.svg"
          }
        ],
        "category": "no-border",
        "column": 4
      }
    },
    {
      "tab": {
        "title": "框架图",
        "subtitle": "",
        "background": "#ffffff",
        "items": [
          {
            "label": "技术架构图",
            "button": {
              "label": "联系我们",
              "link": "https://baas.zhigui.com/application",
              "needLogin": false,
              "style": "primary"
            },
            "frameDiagram": "resource/密码学及安全服务_cn-1545706481611.svg",
            "items": [
              {
                "title": "",
                "description": "为用户提供可选择的高级密码学及安全服务，包括私钥安全存储、硬件安全模块、国密算法支持、数据隐私保护等。针对用户对机密性、安全性、隐私保护、系统性能等方面的不同需求，提供特定服务。"
              }
            ]
          }
        ]
      }
    },
    {
      "image-text": {
        "title": "方案优势",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "硬件私钥保存",
            "description": "提供基于硬件的私钥保存方案，从私钥生成、使用、更新、销毁全生命周期对私钥进行安全管理，确保私钥不触网、不离开硬件设备，从而提升系统的安全性。",
            "icon": "resource/应收账款融资.svg"
          },
          {
            "title": "安全硬件服务",
            "description": "同时提供了针对整个区块链系统的硬件安全方案。通过使用硬件安全模块 ( HSM ) ，实现各种密码算法，安全保存密钥，同时监控整个设备所有功能的运行情况。",
            "icon": "resource/应收账款融资.svg"
          },
          {
            "title": "用户隐私保障",
            "description": "通过零知识证明方案，确保用户在向第三方证明信用、出示身份信息的同时、不泄漏隐私。个人数据全程自主可控，确保用户拥有个人数据主权，保障用户隐私。",
            "icon": "resource/应收账款融资.svg"
          },
          {
            "title": "国密算法支持",
            "description": "提供支持国密算法的区块链服务，满足银行、金融等特定行业的安全需求。",
            "icon": "resource/应收账款融资.svg"
          }
        ],
        "category": "card",
        "column": 4
      }
    }
  ],
  "order": 12,
  "createTime": "2018-12-20T11:39:55.803Z",
  "updateTime": "2019-06-20T09:09:50.351Z"
}