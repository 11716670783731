import React from 'react';
import PropTypes from 'prop-types'

class ServicePage extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.object,
  }

  imageObject(src) {
    if (src.indexOf('resource') !== -1) {
      return require('../../' + src);
    }
    else if (src.indexOf('http') !== -1) {
      return src;
    }

    return require(src);
  }

  renderHead(head) {
    return (
      <div class="brief">
        <div class="brief-center">
          <div class="summary">
            <h3 class="title">SUZHEN</h3>
            <div class="content">
            溯真（  ）是企业级区块链BaaS服务平台，为用户提供息数据存证、行业防伪溯源、全流程供应链管控、数据与流程认证等一系列区块链综合性企业级服务。
            </div>
            {/* <div><a href="" class="link link_apply"> 
                </a><a href="" class="link link_login">
                </a>
            </div> */}
            <div class="link link_login"></div>
          </div>

          <div class="detail">
            <div class="detail-item">
              <h4 class="detail-item-title">全行业极速开发</h4>
              <div class="detail-item-content">
              真链拥有全行业开发能力和成熟的产品，能够让用户最短时间内拥有企业级区块链服务，为用户节约时间成本，极大提升效率。
              </div>
            </div>
            <div class="detail-item">
              <h4 class="detail-item-title">多应用开发扩展</h4><div class="detail-item-content">
              真链为拥有多样的接口及齐全的工具包等，可让用户和开发这在T真连上开发出适应各种场景及落地应用的区块链产品和服务。
              </div>
            </div>
            <div class="detail-item">
              <h4 class="detail-item-title">超级用户全权限</h4><div class="detail-item-content">
              真链可为政府、500强企业和上市公司等需求丰富的组织，提供超级用户全权限服务，帮助其在真链基础上建立私有链保障信息安全的同时能够满足组织全产业多角度的区块链应用需求。
                </div>
              </div>
            <div class="detail-item">
              <h4 class="detail-item-title">上下游延展覆盖</h4><div class="detail-item-content">
              真链不但提供区块链开发服务，还可以根据客户需求为客户提供，区块链应用相关硬件件服务，以及区块链应用相关的大数据、云、人工智能、互联网信息化等相关配套服务。
              </div></div>
            <div class="detail-item">
              <h4 class="detail-item-title">强技术值得信赖</h4><div class="detail-item-content">
              真链技术已经与邮政储蓄银行、招商银行、工商银行等10余家机构合作区块链应用，拥有超强的技术实力和可靠的运维能力。
              </div></div>
            <div class="detail-item">
              <h4 class="detail-item-title">国际级核心团队</h4><div class="detail-item-content">
              团队核心均来自IBM等国际企业中国区的顶级区块链应用专家，拥有高超的技术开发实力和前沿的国际视野，并且拥有丰富的中国政府和企业的实战经验。
              </div></div>
            </div></div></div>

      // <div className="section-solution solution-banner">
      //   <img className="solution-banner-image" src={this.imageObject(head.image)} alt=""></img>
      //   <div>
      //     <h1>{head.title}</h1>
      //     <p>{head.description}</p>
      //   </div>
      // </div>
    );
  }

  renderArchitect(architect) {
    return (
      <div class="section-solution">
        <div class="solution-section-logical fn-normal-padding">
          <h2 class="center">{architect.title}</h2>
          <div>
            <img class="pure-img" src={this.imageObject(architect.items[0].frameDiagram)} alt="" />
          </div>
        </div>
      </div>
    );
  }

  renderSingleFeatures(feature) {
    return (
      <div className="home-case home-case-finance">
        <img className="home-case-icon" src={this.imageObject(feature.icon)} alt="" />
        <h3>{feature.title}</h3>
        <div className="home-case-desc">
          {feature.property.map((fp) => {
            return (<p>{fp}</p>);
          })}
        </div>
        {/* <div className="home-case-extra">
          <p>{feature.description}</p>
          <h4>{feature.example.title}</h4>
          <div className="home-case-extra-client">
            {feature.example.items.map((ex) => {
              return (<div>{ex}</div>);
            })}
          </div>
        </div> */}
      </div>
    );
  }

  renderSingleFeaturesM(feature) {
    return (
      <div className="home-case-m">
        <div className="home-case-m-header"><img className="home-case-icon" src={require("../../resource/知识产权.svg")}
          alt="" />
          <h3>{feature.title}</h3><i></i>
        </div>
        <div className="home-case-desc-m">
          <p>{feature.property.join(' · ')}</p>
        </div>
        <div className="home-case-extra-m">
          <p>{feature.description}</p>
          {/* <h4>{feature.example.title}</h4>
          <div className="home-case-extra-client">
            {feature.example.items.map((ex) => {
              return (<div>{ex}</div>);
            })}
          </div> */}
        </div>
      </div>
    );
  }

  renderFeatures(features) {
    return (
      <div className="section-home" style={{ 'backgroundColor': '#EBECF4' }}>
          <div className="home-section-cases" style={{ overflow: "visible" }}>
            <h2 className="center">{features.title}</h2>
            <p className="center">{features.description}</p>
            <div className="home-cases-controls" aria-label="Carousel Navigation" tabindex="0"><button className="pre"
              aria-controls="tns2" tabindex="-1" data-controls="prev" disabled=""> </button><button className="next"
                aria-controls="tns2" tabindex="-1" data-controls="next"> </button></div>
            <div className="tns-outer tns-ovh">
              <div className="tns-inner" id="tns2-iw">
                <div className="pure-g home-cases tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal" id="tns2"
                  style={{ transform: "translate3d(0%, 0px, 0px)", width: "100%" }}>
                  {features.items.map((item, idx) => {
                    const domId = 'tns2-item' + idx;
                    let className = 'pure-u-1-2 pure-u-lg-1-4 tns-item';
                    if (features.items.length < 4) {
                      className = `pure-u-1-2 pure-u-lg-1-${features.items.length} tns-item`;
                    }
                    if (idx < 3) {
                      className += 'tns-slide-active';

                      return (
                        <div className={className} id={domId} aria-hidden="false">
                          {this.renderSingleFeatures(item)}
                        </div>
                      );
                    } else {
                      return (
                        <div className={className} id={domId} aria-hidden="true" tabindex="-1">
                          {this.renderSingleFeatures(item)}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>

            <div className="pure-g home-cases-m">
              {features.items.map((item) => {
                return (
                  <div className="pure-u-1-1">
                    {this.renderSingleFeaturesM(item)}
                  </div>
                );
              })}
            </div>            
          </div>
        </div>
    );
  }

  render() {
    let head, productionTab, features, architect, niubility;
    this.props.data.modules.map((item) => {
      if (item.head) {
        head = item.head;
      } else if (item.productionTab) {
        productionTab = item.productionTab;
      } else if (item['image-text']) {
        if (!features) {
          features = item['image-text'];
        } else if (!niubility) {
          niubility = item['image-text'];
        }
      } else if (item.tab) {
        architect = item.tab;
      }
    });

    return (
      <div>
        {head && this.renderHead(head)}
        {features && this.renderFeatures(features)}
        {architect && this.renderArchitect(architect)}        
      </div>
    );
  }
}

export default ServicePage;