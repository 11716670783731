export default {
  "id": "5c1b4f862eb328001b7c29a0",
  "modules": [
    {
      "head": {
        "title": "供应链金融管控",
        "description": "将供应链金融体系和区块链相融合，在供应链中的众多企业和金融机构间共建可信的信息网络生态，共享各类信息，减少在数据收集、校验、评估等环节的时间，传递信任，解决中小企业融资成本高的问题。",
        "category": "行业",
        "introduction": "将供应链金融体系和区块链相融合，在供应链中的众多企业和金融机构间共建可信的信息网络生态，共享各类信息，减少在数据收集、校验、评估等环节的时间，传递信任，解决中小企业融资成本高的问题。",
        "buttons": [
          {
            "label": "联系我们",
            "link": "https://baas.zhigui.com/application",
            "needLogin": false,
            "style": "primary"
          }
        ],
        "image": "resource/banner44.svg"
      }
    },
    {
      "productionTab": {
        "title": "方案介绍",
        "subtitle": "",
        "background": "#ffffff",
        "pattern": "horizontal",
        "image": "resource/供应链金融CN-1545801282368.svg",
        "items": [
          {
            "title": "打破信息孤岛",
            "description": "统一供应链上下游企业之间的信息系统和信息格式，使全链条信息融汇贯通。"
          },
          {
            "title": "核心企业信任多级传递",
            "description": "全链条信任渗透传递，实现向二级或多级供应商跨级传递。帮助银行构建可信的业务场景，提高中小企业的融资成功率。"
          },
          {
            "title": "降低融资成本",
            "description": "有效传递信用背书，提升中小企业融资成功率，降低融资成本。"
          }
        ]
      }
    },
    {
      "image-text": {
        "title": "应用场景",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "应收账款融资",
            "description": "通过融合区块链技术，实现票据信息链上存证与流转，在可信的共享环境中实现票据贴现、保理、福费廷等供应链业务，降低信用评估流程和成本，为中小企业融资增信。",
            "icon": "resource/应收账款融资.svg"
          },
          {
            "title": "金融票据流转",
            "description": "银行等金融机构与核心企业之间通过构建联盟链生态，将供应链上的所有成员纳入其中，利用区块链多方签名、不可篡改的特点，使得债券等票据转让得到多方共识，提升流转效率。",
            "icon": "resource/金融票据流转.svg"
          },
          {
            "title": "应付账款融资",
            "description": "在买方主导的应付账款融资场景中，将数字化信息及时共享给企业和提供动产质押融资的金融机构，资金供给方通过链上的数据进行信息刻画，提升融资效率。",
            "icon": "resource/应付账款融资.svg"
          },
          {
            "title": "实物融资",
            "description": "实物融资包括存货质押、现货物流等模式，通过供应链、物联网等技术可以更好地帮助资金供给方了解企业的实物状态信息和真实业务场景，在充分的监管下完成有效的融资授信。",
            "icon": "resource/实物融资.svg"
          }
        ],
        "category": "no-border",
        "column": 4
      }
    },
    {
      "tab": {
        "title": "架构图",
        "subtitle": "",
        "background": "#ffffff",
        "items": [
          {
            "label": "应收账款融资业务",
            "button": {
              "label": "联系我们",
              "link": "https://baas.zhigui.com/application",
              "needLogin": false,
              "style": "primary"
            },
            "frameDiagram": "resource/应收账款_cn-1545702611422.svg",
            "items": [
              {
                "title": "",
                "description": "链上各参与主体从源头上获取了真实有效的数据，资金需求方将企业各类信息上传到区块链平台，供货企业则履行筛选标的，票据摘牌的职责，应收账款信息与应付账款信息及时准确更新并同步给交易双方以及金融主体，将数字化信息及时与企业和提供动产质押融资的金融机构共享，资金供给方通过链上的数据进行信息刻画，降低信用评估流程和成本，提高融资效率，从而构建了全新可靠的供应链信用体系。"
              }
            ]
          },
          {
            "label": "货押融资业务",
            "button": {
              "label": "联系我们",
              "link": "https://baas.zhigui.com/application",
              "needLogin": false,
              "style": "primary"
            },
            "frameDiagram": "货押融资_cn-1545648578558.svg",
            "items": [
              {
                "title": "",
                "description": "货押融资以仓储货物作为质押，来盘活上游供应企业的资金。通过记录联盟链上各参与主体资质、交易等信息，基于区块链的不可篡改性，货押融资流程清晰可见，以此为依托对各主体的交易、兑付情况进行建模分析，构建信用体系；通过对各参与主体减少线下采集审核信息所消耗的时间和人力，使各主体间公开透明地共享真实信息，真实勾勒企业的运营和资产情况，完成信用背书，动态评估融资服务，有效解决融资难的问题；让库存快速变现，并基于此构建贸易良性循环融资生态。"
              }
            ]
          }
        ]
      }
    },
    {
      "image-text": {
        "title": "方案优势",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "中心化的 PKI 体系",
            "description": "将相关企业、银行、保理机构、监管方等各参与方作为节点上链，通过链下确认与链上授权结合的方式，保证各方在区块链上进行安全、可信的协作。",
            "icon": "resource/应收账款融资.svg"
          },
          {
            "title": "数据隐私保护",
            "description": "通过Z-Ledger联盟链权限管理技术，并引入零知识证明等密码学安全模块，在保证有效传递参与企业信用的同时，保护各参与方的商业隐私。",
            "icon": "resource/应收账款融资.svg"
          },
          {
            "title": "高可用性",
            "description": "纸贵区块链支持程序包、docker、kubernetes 等多种部署运维方案，支持微服务、服务网格、云原生等部署运维策略，构造分布式服务体系保障系统的高可用。",
            "icon": "resource/应收账款融资.svg"
          },
          {
            "title": "数据安全",
            "description": "支持国密算法的区块链服务，并通过硬件安全设备实现对密钥的安全保存及转账操作的安全实施，满足银行、金融等特定行业的安全需求。",
            "icon": "resource/应收账款融资.svg"
          }
        ],
        "category": "card",
        "column": 4
      }
    }
  ],
  "order": 6,
  "createTime": "2018-12-20T08:15:02.006Z",
  "updateTime": "2019-01-03T07:24:55.079Z"
}