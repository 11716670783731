export default {
  "id": "5c1517a42eb328001b7c28c4",
  "modules": [
    {
      "head": {
        "title": "SUZHEN",
        "description": "溯真（  ）是企业级区块链BaaS服务平台，为用户提供息数据存证、行业防伪溯源、全流程供应链管控、数据与流程认证等一系列区块链综合性企业级服务。",
        "category": "行业",
        "buttons": [
          {
            "label": "联系我们",
            "link": "https://baas.zhigui.com/application",
            "needLogin": false,
            "style": "primary"
          }
        ],
        "image": "resource/banner44.svg",
        "introduction": "企业级区块链BaaS服务平台"
      }
    },
    {
      "productionTab": {
        "title": "方案介绍",
        "subtitle": "",
        "background": "#ffffff",
        "pattern": "horizontal",
        "image": "resource/存证 CN-1545362890320.svg",
        "items": [
          {
            "title": "支持多种数据存证",
            "description": "支持文本、图片、音频、视频、代码、商标、策划等多类型的知识产权及原创作品的存证与版权申请，同时支持电子据凭证类的数据存证。"
          },
          {
            "title": "数据链上登记",
            "description": "支持在存证链上存储数据指纹和存证时间戳，数据指纹即存证数据的哈希值，可以有效标识数据的版权信息同时占用较少的存储资源。"
          },
          {
            "title": "具有法律效力的存证证书",
            "description": "与多家司法单位合作，可生成具有法律效力的数据存证证书，保障数据的安全合法性。"
          }
        ]
      }
    },
    {
      "image-text": {
        "title": "核心服务",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "品质溯源管理",
            "description": "真链拥有全行业开发能力和成熟的产品，能够让用户最短时间内拥有企业级区块链服务，为用户节约时间成本，极大提升效率。",
            "icon": "resource/奢侈品溯源.svg",
            "property": [
              "信息管理", "防伪溯源", "供应链条","用户反馈"
            ],
            "example": {
              "title": "",
              "items": []
            }
          },
          {
            "title": "数据管理服务",
            "description": "真链为拥有多样的接口及齐全的工具包等，可让用户和开发这在T真连上开发出适应各种场景及落地应用的区块链产品和服务。",
            "icon": "resource/选择服务.svg",
            "property": [
              "信息存证", "信息认证", "数据分析","增值管理"
            ],
            "example": {
              "title": "客户案例",
              "items": [ "山东省药监局", "建设银行", "云品荟"
              ]
            }
          },
          {
            "title": "三方认证服务",
            "description": "真链可为政府、500强企业和上市公司等需求丰富的组织，提供超级用户全权限服务，帮助其在真链基础上建立私有链保障信息安全的同时能够满足组织全产业多角度的区块链应用需求。",
            "icon": "resource/三方认证服务.svg",
            "property": [
              "质量体系认证", "信息真伪认证", "政务保全认证","身份安全认证"
            ],
            "example": {
              "title": "客户案例",
              "items": [ "飞洛供应链", "西美保理", "浙商银行", "爱心人寿"
              ]
            }
          }
        ],
        "category": "no-border",
        "column": 4
      }
    },
    {
      "tab": {
        "title": "示范案例",
        "subtitle": "",
        "background": "#ffffff",
        "items": [
          {
            "label": "",
            "button": {
              "label": "联系我们",
              "link": "https://baas.zhigui.com/application",
              "needLogin": false,
              "style": "primary"
            },
            "frameDiagram": "resource/示范案例.svg",
            "items": [
              {
                "title": "",
                "description": "区块链存证解决方案系统架构包括区块链资源层、区块链底层、区块链应用层和区块链业务层四个部分。系统在底层资源的基础上，通过容器服务构建区块链底层，融合DID、Oracle、分布式存储等模块化的底层技术，为上层应用提供存证、监测、取证、公证和交易等功能支持，以覆盖更广泛的业务场景。"
              }
            ]
          }
        ]
      }
    },
  ],
  "order": 4,
  "createTime": "2018-12-15T15:03:00.255Z",
  "updateTime": "2019-01-03T07:24:20.541Z"
}