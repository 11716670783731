export default {
  "id": "5c1b52902eb328001b7c29af",
  "modules": [
    {
      "head": {
        "title": "数字资产管理",
        "description": "团队核心均来自IBM等国际企业中国区的顶级区块链应用专家，拥有高超的技术开发实力和前沿的国际视野，并且拥有丰富的中国政府和企业的实战经验。",
        "category": "行业",
        "introduction": "基于真链底层搭建的数字资产管理平台，通过区块链加密技术管理数字资产，让用户在数字生活中的行为产生价值回报。在社交、娱乐、购物、出行等方面，这些行为产生的价值将被得到重视，用户的数字行为得以资产化。",
        "buttons": [
          {
            "label": "联系我们",
            "link": "https://baas.zhigui.com/application",
            "needLogin": false,
            "style": "primary"
          }
        ],
        "image": "resource/banner44.svg"
      }
    },
    {
      "productionTab": {
        "title": "方案介绍",
        "subtitle": "",
        "background": "#ffffff",
        "pattern": "vertical",
        "image": "resource/积分CN-1545620239662.svg",
        "items": [
          {
            "title": "",
            "description": "借助区块链去中心化、可信、透明、不可篡改等技术特点，打通平台之间的积分兑换通道，构建灵活高效、快速发行、数据安全、性能领先的商业级区块链数字平台，共享积分的兑换，实现数字资产的发行，商业系统的对接以及积分价值的有效流通，提升用户的积分兑换意愿，进而通过积分生态带动平台间的产品消费"
          }
        ]
      }
    },
    {
      "image-text": {
        "title": "应用场景",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "单体积分",
            "description": "在原有单体积分解决方案上进行“区块链+”升级，为用户提供自主可控的数字积分和流通体系，提升积分价值。",
            "icon": "resource/单体积分.svg"
          },
          {
            "title": "通用积分",
            "description": "面向众多商家发行同一种区块链数字积分，并支持单体积分与通用积分的兑换，享受通用积分的权益和服务。",
            "icon": "resource/通用积分.svg"
          },
          {
            "title": "限量版权益",
            "description": "通过例如产品周年庆、节假日、新品发布等一系列场景中，发行限量版的权益证明，结合区块链不可篡改，永久储存的特性，提升用户荣誉感。",
            "icon": "resource/限量版权益.svg"
          },
          {
            "title": "积分兑换",
            "description": "通过区块链成熟的积分技术标准，实现跨主体、跨平台、跨业务的多积分存储、转账、兑换功能，实现去中心化的积分兑换、积分商城等业务功能。",
            "icon": "resource/积分兑换.svg"
          }
        ],
        "category": "no-border",
        "column": 4
      }
    },
    {
      "tab": {
        "title": "方案架构",
        "subtitle": "",
        "background": "#ffffff",
        "items": [
          {
            "label": "通用积分业务",
            "button": {
              "label": "联系我们",
              "link": "https://baas.zhigui.com/application",
              "needLogin": false,
              "style": "primary"
            },
            "frameDiagram": "resource/通用积分业务_cn-1545706249238.svg",
            "items": [
              {
                "title": "",
                "description": "传统平台积分分散、不通用，不同行业，不同企业各自发行不同的积分，对积分的查询和使用都不便捷，且由于小企业的积分基于中心化数据库，存在篡改伪造的风险，使得公信力不足。基于区块链的通用积分平台实现了积分的互通流转，所有节点信息上链，保证了系统发行的透明性，积分的发行与流转做到可追溯，有利于提高用户粘性。"
              }
            ]
          },
          {
            "label": "系统架构",
            "button": {
              "label": "联系我们",
              "link": "https://baas.zhigui.com/application",
              "needLogin": false,
              "style": "primary"
            },
            "frameDiagram": "积分_cn-1545706296928.svg",
            "items": [
              {
                "title": "",
                "description": "将积分上下游生态相关的各级业务系统通过联盟链机制部署在区块链上，分级运营管理，消除信息不透明的壁垒，平台之间共享兑换机制，不同场景下产生的积分可以叠加使用，激活积分兑换平台活力，有利于企业建立用户黏性，同时依托于底层云资源的特性可以大大节省企业的TCO的成本，实现平台的轻量化运营。"
              }
            ]
          }
        ]
      }
    },
    {
      "image-text": {
        "title": "方案优势",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "原生链上的账户与积分模块",
            "description": "用户可通过离线工具生成私钥和公钥，并通过私钥操作账户。账户作为与生态进行积分交互的载体，可进行积分的管理和交易。纸贵许可链原生集成了积分模块，能够在任意使用场景中实现高并发的积分转移。",
            "icon": "resource/积分兑换.svg"
          },
          {
            "title": "数据隐私保护",
            "description": "通过数据加密技术，客户可以将数据做本地加密编码后再上传至区块链，加密数据仅自身可以解码，保证用户数据的隐私性。且支持对不同节点的分组和权限管理，可以实现第三方数据的可信授权访问，让数据查看权掌握在客户自己手中。",
            "icon": "resource/积分兑换.svg"
          },
          {
            "title": "跨链积分流通",
            "description": "针对积分需要在不同平台、不同生态中进行流转的需求，纸贵积分区块链支持跨链互操作，使得信息、价值可以在不同平台、不同区块链上可信、安全流转。客户可以据此实现更灵活的业务及产品设计。",
            "icon": "resource/积分兑换.svg"
          },
          {
            "title": "私有化部署",
            "description": "针对有强数据安全和数据隐私的客户，可以实施私有化部署，实现数据的自主可控，保证数据安全。",
            "icon": "resource/积分兑换.svg"
          }
        ],
        "category": "card",
        "column": 4
      }
    }
  ],
  "order": 7,
  "createTime": "2018-12-20T08:28:00.771Z",
  "updateTime": "2019-02-15T08:05:33.698Z"
}