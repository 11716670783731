import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';


const menus = [
  { title: "首页", link: "./", items: [] },
  { title: "产品与服务", link: "./service", items: [] },
  {
    title: "解决方案", link: "", items: [
      { title: "数据存证", link: "./evidence" },
      { title: "品质溯源", link: "./source" },
      { title: "数字资产管理", link: "./digital" },
      { title: "供应链金融管控", link: "./chain" },
      { title: "保密安全服务", link: "./secury" },
      { title: "可信身份服务", link: "./identifier" },
    ]
  },
  { title : "溯真学院", link: "./training", items: [] },
  { title: "关于我们", link: "./about", items: [] }
];

class Header extends React.Component {
  static propTypes = {
    title: PropTypes.string,
  }

  render() {
    return (
      <div className="header">
        <img className="header-hamburger" src={require("../../resource/icon/menu.svg")} alt="" />
        <a className="logo-wrap">
          <img className="header-logo logo" src={require("../../resource/logo.svg")} alt="中企数字logo" />
        </a>

        <div className="pure-menu pure-menu-horizontal">
          <ul className="pure-menu-list">
            {menus.map((item, idx) => {
              if (item.items && item.items.length) {
                return (
                  <li className="pure-menu-item pure-menu-has-children pure-menu-allow-hover "><span
                    className="pure-menu-link">{item.title}</span>
                    <ul className="pure-menu-children">
                      {item.items.map((subitem) => {
                        return (
                          <li className="pure-menu-item"><a className="pure-menu-link"><Link to={subitem.link} className="text-link">{subitem.title}</Link></a></li>
                        );
                      })}
                    </ul>
                  </li>
                );
              }
              else {
                if (idx === 0) {
                  return (
                    <li className="pure-menu-item pure-menu-selected">
                      <a className="pure-menu-link menu-selected">
                        <Link to={item.link} className="text-link">{item.title}</Link>
                      </a>
                    </li>
                  );
                }

                return (
                  <li className="pure-menu-item">
                    <a className="pure-menu-link">
                      <Link to={item.link} className="text-link">{item.title}</Link>
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        </div>

        <div className="header-side-menu" style={{ display: 'none' }}>
          <div className="header-side-menu-main" style={{ left: '-80vw' }}>
            <div className="header-side-menu-wrapper">
              <div className="header-side-menu-logo">
                <a><Link to="./" className="text-link"><img className="header-logo"
                  src={require("../../resource/logo.svg")} alt="" /></Link></a>
              </div>

              {menus.map((item, idx) => {
                if (item.items && item.items.length) {
                  return (
                    <div className="header-side-menu-sub"><span>{item.title}</span><i></i>
                      <div className="header-side-menu-sub-items">
                        {item.items.map((subitem) => {
                          return (
                            <div><a><Link to={subitem.link} className="text-link">{subitem.title}</Link></a></div>
                          );
                        })}
                      </div>
                    </div>
                  );
                } else {
                  if (idx === 0) {
                    return (
                      <div className="header-side-menu-item"><a className="menu-selected"><Link to={item.link} className="text-link">{item.title}</Link></a></div>
                    );
                  } else {
                    return (
                      <div className="header-side-menu-item"><a><Link to={item.link} className="text-link">{item.title}</Link></a></div>
                    );
                  }
                }
              })}
            </div>

            <div className="header-lang"><a className="active">中</a><span>/</span><a
            >En</a></div>
          </div>
        </div>

        <div className="header-lang desktop"><a className="active">中</a><span>/</span><a
        >En</a></div>
      </div>
    );
  }
}

export default Header;