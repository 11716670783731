export default {
  "id": "5c151b332eb328001b7c28ca",
  "modules": [
    {
      "head": {
        "title": "品质溯源",
        "description": "通过采集和跟踪产品从生产到消费的各环节数据，构建基于区块链的溯源信息共享平台，实现产品全链条的精细化管理，支持消费者、监管部门进行查验和审计，防范供应链中的鱼龙混杂，销售渠道中的假冒伪劣商品。实现多粒度信息共享，保护企业的商业隐私",
        "category": "行业",
        "buttons": [
          {
            "label": "联系我们",
            "link": "https://baas.zhigui.com/application",
            "needLogin": false,
            "style": "primary"
          }
        ],
        "image": "resource/banner44.svg",
        "introduction": "商品全链条信息采集、上链与审计"
      }
    },
    {
      "productionTab": {
        "title": "方案介绍",
        "subtitle": "",
        "background": "#ffffff",
        "pattern": "horizontal",
        "image": "resource/溯源CN-1545390652673.svg",
        "items": [
          {
            "title": "强化供应链管理",
            "description": "商品生产全流程信息上链，建立防伪管理机制，以可视化的供应链管理模式，优化质量和流程管理，提升产品和品牌价值"
          },
          {
            "title": "保障消费安全",
            "description": "提供产品信息溯源平台 ，支持商品真伪在线验证，假冒产品源头可追溯"
          },
          {
            "title": "共建产品溯源示范区",
            "description": "协助制定管理标准和规范，提升质量监管效率和政府公信力\n"
          }
        ]
      }
    },
    {
      "image-text": {
        "title": "应用场景",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "农产品溯源",
            "description": "基于去中心化、透明可信的溯源许可链平台，接入农产品生产、加工等追溯信息，结合节点上仓储出入库、订单、物流等数据，将农产品全流程品质追溯信息展现给消费者。",
            "icon": "resource/强化供应链管理.svg"
          },
          {
            "title": "医疗药品溯源",
            "description": "所有医疗药品从原材料生产起即在区块链上登记，为药品相关生产销售等资料提供一系列的存证服务，降低在采购、实验、测试、生产、消费过程中的可能出现的错误风险。",
            "icon": "resource/保障消费安全.svg"
          },
          {
            "title": "物联网+供应链溯源",
            "description": "在供应链上的各个主体部署区块链节点，通过实时和离线等方式，将传感器收集的数据写入区块链，记录物品对应的流通防伪信息，可以提升各方主体造假抵赖的成本，实现供应链全流程溯源。",
            "icon": "resource/共建产品溯源示范区.svg"
          },
          {
            "title": "奢侈品溯源",
            "description": "为奢侈品供应链上的各方提供区块链服务支持，包括奢侈品经销商、加工公司、海关、 物流和交易中心等。在多中心化网络上提供高透明度的流程信息，实现从奢侈品生产、分销、到销售的全程信息流追溯。",
            "icon": "resource/共建产品溯源示范区.svg"
          }
        ],
        "category": "no-border",
        "column": 4
      }
    },
    {
      "tab": {
        "title": "方案架构",
        "subtitle": "",
        "background": "#ffffff",
        "items": [
          {
            "label": "业务架构",
            "button": {
              "label": "联系我们",
              "link": "https://baas.zhigui.com/application",
              "needLogin": false,
              "style": "primary"
            },
            "frameDiagram": "resource/溯源系统构架_cn-1545727153644.svg",
            "items": [
              {
                "title": "",
                "description": "基于区块链的全程化的溯源追踪管理体系，将产品从原材料的选取到最后被消费者购买的整个环节信息都上链，实现商品信息的实时溯源和不可篡改，保障消费者的购买权益；从企业角度看，加强了企业质量管理，降低多方合作的成本，包括信任成本，资产，资金的信任成本等。以此为依托的数据更可靠，所有参与者对数据负责，为大数据分析提供可靠的数据源。"
              }
            ]
          },
          {
            "label": "系统架构",
            "button": {
              "label": "联系我们",
              "link": "https://baas.zhigui.com/application",
              "needLogin": false,
              "style": "primary"
            },
            "frameDiagram": "resource/溯源系统构架_cn-1545727153644.svg",
            "items": [
              {
                "title": "",
                "description": "纸贵溯源区块链解决方案包含区块链、传输感知、中央管理、企业管理和业务应用五个部分，通过区块链、物联网等多方面技术的融合，实现溯源场景下的区块链落地。纸贵科技企业级区块链解决方案提供了产品、部署、定制化开发、端到端团队保障，以及全流程的专业项目管理等服务，通过灵活高效的 API & SDK，助力业务创新，可实现快速的业务集成和上线，保证业务系统运行稳定可靠。"
              }
            ]
          }
        ]
      }
    },
    {
      "image-text": {
        "title": "方案优势",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "引入DID和可验证声明技术",
            "description": "为每一件商品注册唯一DID，将产品信息写入DID描述文件中，各环节参与方通过DID为产品签发和校验声明，实现产品全声明周期信息可信上链。",
            "icon": "resource/农产品溯源.svg"
          },
          {
            "title": "引入二维码和RFID标签",
            "description": "满足不同场景下对产品的标记需求，并通过可信安全计算硬件确保产品流通环节中由专人负责信息验证与可信上链，出现问题时可以精确定位到参与个人或设备。",
            "icon": "resource/医疗产品溯源.svg"
          }
        ],
        "category": "card",
        "column": 2
      }
    },
    {
      "image-text": {
        "title": "应用案例",
        "subtitle": "",
        "background": "#ffffff",
        "items": [
          {
            "title": "天水链苹",
            "description": "纸贵科技与甘肃天水市林业局合作，应用一物一码、纸贵许可链Z-Ledger、分布式身份标识（DID）等诸多先进区块链技术，提供了果品溯源产品，各参与节点都将采集到的数据记录到区块链上，实现果品生产上下游的追溯体系。为构建来源可查，去向可追，责任可究的全链条可追溯系统奠定基础。此案例也于2018年10月10日被纳为可信区块链溯源白皮书中的成功案例之一。",
            "icon": "微信图片_20181022145506-1540191311481-1545292793704.png"
          }
        ],
        "category": "case",
        "column": 1
      }
    }
  ],
  "order": 5,
  "createTime": "2018-12-15T15:18:11.603Z",
  "updateTime": "2019-01-03T07:24:39.532Z"
}