import React from 'react';
import ReactDOM from 'react-dom';

import './resource/pure-min.css'
import './resource/page.css'
import './resource/tiny-slider.css'
import './resource/grids-responsive-min.css'
import './index.css';
import './resource/index.css'

import gongyinglian from './mockdata/gongyinglian'
import cunzheng from './mockdata/cunzheng'
import mima from './mockdata/mima'
import suyuan from './mockdata/suyuan'
import shuzijifen from './mockdata/shuzijifen'
import shenfen from './mockdata/shenfen'
import service from './mockdata/service'

import HomePage from './pages/home';
import SolutionPage from './pages/solution';
import Header from './components/header'
import AboutPage from './pages/about'
import Footer from './components/footer'
import ServicePage from './pages/service';
import TrainingPage from './pages/training';

import { HashRouter as Router, Route, Switch, BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  (
    <BrowserRouter basename="/" forceRefresh={true}>
      <Header/>

      <Switch>
        <Route exact path="/"> <HomePage /> </Route>
      </Switch>

      <Switch>
        <Route path="/source"> <SolutionPage data={suyuan} /> </Route>
      </Switch>

      <Switch>
        <Route path="/evidence"> <SolutionPage data={cunzheng} /> </Route>
      </Switch>

      <Switch>
        <Route path="/digital"> <SolutionPage data={shuzijifen} /> </Route>
      </Switch>

      <Switch>
        <Route path="/chain"> <SolutionPage data={gongyinglian} /> </Route>
      </Switch>

      <Switch>
        <Route path="/secury"> <SolutionPage data={mima} /> </Route>
      </Switch>

      <Switch>
        <Route path="/identifier"> <SolutionPage data={shenfen} /> </Route>
      </Switch>

      <Switch>
        <Route path="/about"> <AboutPage /> </Route>
      </Switch>

      <Switch>
        <Route path="/service"> <ServicePage data={service} /> </Route>
      </Switch>

      <Switch>
        <Route path="/training"> <TrainingPage /> </Route>
      </Switch>

      <Footer />
    </BrowserRouter>
  ), document.getElementById('root'));