import React from 'react';
import PropTypes from 'prop-types'

import bannerImage from '../../resource/区块链教育培训top配图.svg'

class TrainingPage extends React.Component {
  static propTypes = {
    title: PropTypes.string,
  }

  imageObject(src) {
    if (src.indexOf('resource') !== -1) {
      return require('../../' + src);
    }
    else if (src.indexOf('http') !== -1) {
      return src;
    }

    return require(src);
  }

  render() {
    const bg = {
      backgroundImage: `url(${require("../../resource/区块链教育培训top配图.svg")})`,
      backgroundSize: 'contain',
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat'
    };

    return (
      <div>
        <div>
          <div className="train-banner">
            <div style={bg}>
              <div className="train-banner-content">
                <h1>区块链教育培训</h1>
                <p>培养区块链综合型人才</p>
                <div className="train-banner-link"><a className="product-btn-link"
                  href="./training" target="_blank"><span>联系我们</span></a></div>
                <p>欢迎有培训需求或培训资源的各大公司、机构联系中企数字培训部探讨合作</p>
                <img className="mobile" src={this.imageObject("resource/mobile-banner@2x.png")}
                  alt="手机banner" />
                <div>
                  <p>
                    <span>习近平总书记在中央政治局第十八次集体学习时强调把区块链 作为核心技术自主创新重要突破口，加快推动区块链技术和产业创新发展，为响应总书记的号召，中企数字推出区块链教育培训版块——溯真学堂。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="section train-sec-adv">
            <div>
              <h2>中企数字的优势</h2>
              <p>对技术的角逐成为众多企业和机构提升核心竞争力的关键</p>
              <div className="train-advs">
                <div>
                  <div className="train-adv" data-name="bg"><img src={this.imageObject("resource/adv-01@2x.png")} alt="学术背景" />
                    <h3>雄厚的师资团队</h3>
                    <p>核心讲师团队包括：</p>
                    <p>1）知名讲师，具有博士学位，有丰富的知名高校区块链课程主讲经验和区块链实践经验；</p>
                    <p>2）公司一线开发人员，具有丰富的区块链开发经验。</p><br />
                    <p>外聘讲师团队包括：</p>
                    <p>1）知名高校、研究院所及相关领域教授专家；</p>
                    <p>2）国内外区块链项目的主管及核心技术人员。</p>
                  </div>
                </div>
                <div>
                  <div className="train-adv" data-name="prac"><img src={this.imageObject("resource/adv-02@2x.png")} alt="落地实践" />
                    <h3>最新的落地实践</h3>
                    <p>中企数字是目前国内在银行等金融行业落地应用案例最多的区块链技术公司，我们的区块链底层平台已在多家金融机构落地应用。</p>
                    <p>在非金融领域，中企数字也与谷歌、甲骨文科技、医伴金服等巨头企业积极拓展物流供应链、溯源防伪等场景的应用。</p>
                  </div>
                </div>
                <div>
                  <div className="train-adv" data-name="pow"><img src={this.imageObject("resource/adv-03@2x.png")} alt="技术能力" />
                    <h3>全面的技术能力</h3>
                    <p>中企数字坚持自主研发区块链底层核心技术，具有国内外领先的技术实力，打造的国产自主可控企业级联盟链平台 Hyperchain 在多个权威机构评测中均名列第一。</p>
                    <p>中企数字积极参与国际开源社区交流与建设。对Hyperledger、以太坊等主流区块链平台也均有跟踪研究。</p>
                  </div>
                </div>
                <div>
                  <div className="train-adv" data-name="exp"><img src={this.imageObject("resource/adv-04@2x.png")} alt="教学经验" />
                    <h3>丰富的教学经验</h3>
                    <p>中企数字致力于培养高层次、复合型的区块链人才，推动区块链行业产学研协同发展。</p>
                    <p>目前中企数字已为数十家金融机构、企业、政府单位普及区块链专业知识和实践案例，并多次联合浙江大学等机构发起区块链专业培训，为行业培养和输送人才。</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="section-train train-sec-busi">
            <div className="center">
              <h2>培训方向</h2>
              <p>综合型人才打造赋区块链创新</p>
              <div className="train-conts">
                <div className="pure-u-1-1 pure-u-md-1-2">
                  <div className="train-cont"><img src={this.imageObject("resource/busi-01@2x.png")} alt="企业内训" />
                    <h3>政府企业内训</h3><b>面向政府和企事业单位</b>
                    <p>根据单位机构需求和领域特性，结合区块链技术在该领域的应用案例和未来趋势制定教学方案。</p>
                  </div>
                </div>
                <div className="pure-u-1-1 pure-u-md-1-2">
                  <div className="train-cont"><img src={this.imageObject("resource/busi-02@2x.png")} alt="公众培训" />
                    <h3>大众普及培训</h3><b>大众普及培训</b>
                    <p>根据学员行业、工作岗位和需求，开设不同类型的课程，让学员具备科学、系统的区块链知识，从而应用到学习和生产工作中。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="section train-sec-mtl">
            <div className="pure-g">
              <div className="pure-u-1-1 pure-u-md-1-2 train-mtl-intro">
                <div>
                  <h2>培训材料</h2>
                  <p>
                    <span>因“材”施教，用最专业的教材，培养最拔尖的人才。</span><br /><br /><span>“区块链+”培训的官方教材涵盖：</span><br /><span>1、全套区块链精品讲义；</span><br /><span>2、中企数字资深专家团队自主编写的区块链方向专业书籍。</span>
                  </p><small className="desktop">更多精品教材正在开发编写中。</small>
                </div>
              </div>

              <div class="train-book">
                <img src={this.imageObject("resource/train-01@2x.png")} alt="培训材料01" />
                <div class="train-book-info">
                  <h3>《区块链技术进阶与实战》</h3><span>浙江大学区块链专业课程官方教材</span>
                  <p>趣链科技蔡亮、李启雷、梁秀波编写</p>
                  <p>出版日期：2018 年 4 月</p>
                </div>
              </div>

              <div className="train-book-pur-list hidden"><a href="http://www.ituring.com.cn/book/2434" target="_blank"><img
                src={this.imageObject("resource/tu.png")} alt="图灵社区" /><span>图灵社区</span></a><a
                  href="https://www.amazon.cn/dp/B07CBLS79P/" target="_blank"><img src={this.imageObject("resource/ya.png")}
                    alt="亚马逊" /><span>亚马逊</span></a><a href="https://item.jd.com/12340068.html" target="_blank"><img
                      src={this.imageObject("resource/jd.png")} alt="京东" /><span>京东</span></a><a
                        href="http://product.dangdang.com/25255369.html" target="_blank"><img src={this.imageObject("resource/dd.png")}
                          alt="当当" /><span>当当</span></a></div>
            </div>
          </div> */}
        </div>
        {/* <div className="section train-sec-plt">
          <div className="showcase--limg">
            <div><img src={this.imageObject("resource/editor@2x.png")} /></div>
            <div className="vertical-center">
              <h2>实验平台</h2>
              <p>
                <span>一站式的教学体验，从基础到应用全方位体验区块链的魅力。</span><br /><br /><span>“区块链+”培训为广大学员提供区块链在线实验平台。基于该平台，学员可以创建、发布和使用多中心化的应用程序，可在线编写、编译、调试智能合约，提高验证效率，降低开发成本。</span>
              </p><a className="product-btn-link-revert" href="" target="_blank">开始使用</a>
            </div>
          </div>
        </div> */}
        <div className="section train-sec-system">
          <div>
            <h2 className="center">课程体系</h2>
            <p>联合行业顶级专家、结合最新应用案例</p>
            <p>溯真学堂区块链教育课程体系，分为基础入门、思维创新、经济赋能、行业应用、企业战略五大模块。</p>
            <div>
              <div className="train-class open" data-name="basic">
                <div className="train-class-meta">
                  <h3>【基础入门模块】区块链的基本入门知识及发展历程</h3><i></i>
                </div>
                <div>
                  <div className="train-class-intro">
                    <h3>一、数字货币</h3>
                    <p>1、数字货币的起源</p>
                    <p>2、数字货币的运行机制</p>
                    <p>3、数字货币的特点</p>
                    <p>4、数字货币的价值</p>
                  </div>
                  <div className="train-class-intro">
                    <h3>二、区块链技术简介</h3>
                    <p>1、区块链定义</p>
                    <p>2、区块链核心技术</p>
                    <p>3、区块链技术特征</p>
                    <p>4、区块链的来源</p>
                    <p>5、区块链的重要概念</p>
                  </div>
                  <div className="train-class-intro">
                    <h3>三、区块链技术原理</h3>
                    <p>1、通过比特币了解区块链技术的原理</p>
                    <p>2、区块链的分类</p>
                    <p>3、区块链的发展历程</p>
                  </div>
                  <div className="train-class-intro">
                    <h3>四、从数字货币到区块链、数字货币产业</h3>
                    <p>1、区块链的应用场景</p>
                    <p>2、智能合约的意义与应用案例</p>
                  </div>
                  <div className="train-class-intro">
                    <h3>五、TOKEN通证及通证经济模型设计概要</h3>
                    <p>1、通证经济 </p>
                    <p>2、传统行业进行通证设计的必要条件</p>
                    <p>3、通证经济设计原理与方法论</p>
                    <p>4、通证经济设计模型及案例</p>
                  </div>
                  <div className="train-class-intro">
                    <h3>六、区块链的机遇与挑战</h3>
                    <p>1、技术的机遇与挑战</p>
                    <p>2、商业的机遇与挑战</p>
                    <p>3、社会的机遇与挑战</p>
                  </div>
                </div>
              </div>
              <div className="train-class" data-name="opentech">
                <div className="train-class-meta">
                  <h3>【思维创新模块】区块链思维与商业模式创新</h3><i></i>
                </div>
                <div>
                  <div className="train-class-intro">
                    <h3>课程</h3>
                    <p>1、“互联网+”时代与互联网思维</p>
                    <p>2、“区块链+”时代与区块链思维</p>
                    <p>3、社区思维</p>
                    <p>4、共识思维</p>
                    <p>5、去中心化思维</p>
                    <p>6、激励思维</p>
                    <p>7、开源思维</p>
                    <p>8、跨界思维</p>
                  </div>
                </div>
              </div>
              <div className="train-class" data-name="contact">
                <div className="train-class-meta">
                  <h3>【经济赋能模块】区块链如何赋能实体经济</h3><i></i>
                </div>
                <div>
                  <div className="train-class-intro">
                    <h3>一、区块链应用</h3>
                    <p>1、区块链即第二代互联网---价值互联网</p>
                    <p>2、数字货币产业链及行业发展现状</p>
                    <p>3、区块链+金融：资金脱虚向实</p>
                    <p>4、区块链+物流</p>
                    <p>5、区块链+出版</p>
                    <p>6、区块链+游戏</p>
                    <p>7、区块链+物联网</p>
                    <p>8、“区块链+”特点及误区</p>
                  </div>
                  <div className="train-class-intro">
                    <h3>二、区块链在实体经济场景落地的模式与案例</h3>
                    <p>1、区块链与供应链金融</p>
                    <p>2、区块链与知识产权保护</p>
                    <p>3、区块链与商品溯源保障</p>
                    <p>4、区块链与电子政务建设</p>
                    <p>5、区块链与新型智慧城市</p>
                    <p>6、区块链与可信数字经济</p>
                    <p>7、区块链与供给侧改革</p>
                    <p>8、区块链与新型平台经济</p>
                  </div>
                </div>
              </div>
              <div className="train-class" data-name="contact">
                <div className="train-class-meta">
                  <h3>【商业应用模块】解决企业激励方式与绩效方式，提升效率，构建企业共建共赢模式</h3><i></i>
                </div>
                <div>
                  <div className="train-class-intro">
                    <h3>一、区块链的价值与意义—结成公开透明的价值共识</h3>
                    <p>1、从比特币开始认知区块链——价值的凝结过程</p>
                    <p>2、社会大“协作”的演进—— 价值分配的演进</p>
                    <p>3、基于组织结构分工体系——现代商业社会的形成</p>
                    <p>4、“多中心化” 商业萌芽裂变——价值公平的诉求</p>
                    <p>5、基于共享的商业结构——建立基于区块链的公平价值体系</p>
                  </div>
                  <div className="train-class-intro">
                    <h3>二、区块链对于企业绩效管理的实现</h3>
                    <p>1、企业新生态体系的愿景-企业命运共同体</p>
                    <p>2、构建企业命运共同体的关键：
                          ① 以“人”为中心
                          ② 必须 实现共享共赢
                          ③ 企业各涉众必须同网、同规则</p>
                    <p>3、企业命运共同体生态体网的实现—企业与组织结构</p>
                  </div>
                </div>
              </div>
              <div className="train-class" data-name="contact">
                <div className="train-class-meta">
                  <h3>【企业新战略模块】市（币）值为王</h3><i></i>
                </div>
                <div>
                  <div className="train-class-intro">
                    <h3>一、重新定义战略</h3>
                    <p>1、企业发展三战略：
                    利润导向、现金流导向、市值导向</p>
                    <p>2、IPO、ICO、ITO、深圳文交所的版通交易板</p>
                    <p>3、米尔纳的扎克伯格定律</p>
                  </div>
                  <div className="train-class-intro">
                    <h3>二、重新定义竞争</h3>
                    <p>1、企业系统四形态：点、链、面、体</p>
                    <p>2、基于价值链的竞争</p>
                    <p>3、基于商业生态的竞争</p>
                    <p>4、基于数字（体）经济的竞争</p>
                  </div>
                  <div className="train-class-intro">
                    <h3>三、重新定义消费</h3>
                    <p>1、物有所值、物超所值、免费、赚钱</p>
                    <p>2、消费进化的必然发展：消费4.0</p>
                  </div>
                  <div className="train-class-intro">
                    <h3>四、未来的经济形态：通证经济</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="train-break"></div>
      </div>
    );
  }
}

export default TrainingPage;
