export default {
  "id": "5c1517a42eb328001b7c28c4",
  "modules": [
    {
      "head": {
        "title": "数据存证",
        "description": "通过区块链存证平台将版权、合同、赛事纪录、教育培训等记录存储在区块链网络中，同时在线生成具有法律效力和唯一哈希标识的存证证书。记录和文件存证可以联网查询，永久有效，无法篡改。",
        "category": "行业",
        "buttons": [
          {
            "label": "联系我们",
            "link": "https://baas.zhigui.com/application",
            "needLogin": false,
            "style": "primary"
          }
        ],
        "image": "resource/banner44.svg",
        "introduction": "提供基于区块链的数据存证解决方案"
      }
    },
    {
      "productionTab": {
        "title": "方案介绍",
        "subtitle": "",
        "background": "#ffffff",
        "pattern": "horizontal",
        "image": "resource/存证 CN-1545362890320.svg",
        "items": [
          {
            "title": "支持多种数据存证",
            "description": "支持文本、图片、音频、视频、代码、商标、策划等多类型的知识产权及原创作品的存证与版权申请，同时支持电子据凭证类的数据存证。"
          },
          {
            "title": "数据链上登记",
            "description": "支持在存证链上存储数据指纹和存证时间戳，数据指纹即存证数据的哈希值，可以有效标识数据的版权信息同时占用较少的存储资源。"
          },
          {
            "title": "具有法律效力的存证证书",
            "description": "与多家司法单位合作，可生成具有法律效力的数据存证证书，保障数据的安全合法性。"
          }
        ]
      }
    },
    {
      "image-text": {
        "title": "应用场景",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "支持多种数据存证",
            "description": "可根据版权作品的内容特性，通过哈希计算得出对应的数据指纹写入区块链，并生成证书供用户保留。在客户需要对存证的指纹进行验证时，提供数字指纹比对查询。",
            "icon": "resource/支持多种数据存证.svg"
          },
          {
            "title": "数据链上登记",
            "description": "发现侵权行为时，对侵权网站进行页面抓取取证，将侵权行为固化为证据进行保存，数据永久存储且不可篡改。",
            "icon": "resource/数据链上登记.svg"
          },
          {
            "title": "具有法律效力的存证证书",
            "description": "应用于电子发票等多类数字存证场景，实现有效的监管，统一的状态管理，解决多平台间的数据统一和数据共享等问题",
            "icon": "resource/具有法律效力的存证证书.svg"
          },
          {
            "title": "知识产权",
            "description": "支持知识产权在研发、产生、授权、运营、保护、维权、失效全生命周期流程中的管理，并支持通过区块链进行公开透明的知识产权合规交易，做到产权权属、交易过程可共享、可追溯。",
            "icon": "resource/具有法律效力的存证证书.svg"
          }
        ],
        "category": "no-border",
        "column": 4
      }
    },
    {
      "tab": {
        "title": "技术框架",
        "subtitle": "",
        "background": "#ffffff",
        "items": [
          {
            "label": "",
            "button": {
              "label": "联系我们",
              "link": "https://baas.zhigui.com/application",
              "needLogin": false,
              "style": "primary"
            },
            "frameDiagram": "resource/存证技术框架.svg",
            "items": [
              {
                "title": "",
                "description": "区块链存证解决方案系统架构包括区块链资源层、区块链底层、区块链应用层和区块链业务层四个部分。系统在底层资源的基础上，通过容器服务构建区块链底层，融合DID、Oracle、分布式存储等模块化的底层技术，为上层应用提供存证、监测、取证、公证和交易等功能支持，以覆盖更广泛的业务场景。"
              }
            ]
          }
        ]
      }
    },
    {
      "image-text": {
        "title": "产品优势",
        "subtitle": "",
        "background": "#f7f8fa",
        "items": [
          {
            "title": "安全可信",
            "description": "引入基于PKI 体系的身份注册与身份认证，将相关服务部门、公证处等生态参与方作为参与节点上链，保证各方在区块链上进行安全、可信的协作。",
            "icon": "resource/支持多种数据存证.svg"
          },
          {
            "title": "实时登记",
            "description": "记录实时登记，能够快速与公证节点进行确权信息的确认，并实时安全、可靠的保存在区块链上，设立多节点备份机制。 ",
            "icon": "resource/支持多种数据存证.svg"
          },
          {
            "title": "公平公正",
            "description": "提供链上信息查询服务，将所有存证确权、侵权存证等数据公开，任何个人和机构均可进行查询，确保服务公平、公正、公开，促进行业健康成长。",
            "icon": "resource/支持多种数据存证.svg"
          },
          {
            "title": "引入预言机模块",
            "description": "在通过URL获取侵权存证的过程中，引入验证机构约束取证行为，确保取证服务被约束为能且只能获取URL当前时刻对应网页内容，且该约束过程可被验证。",
            "icon": "resource/支持多种数据存证.svg"
          }
        ],
        "category": "no-border",
        "column": 4
      }
    },
    {
      "image-text": {
        "title": "应用案例",
        "subtitle": "",
        "background": "#ffffff",
        "items": [
          {
            "title": "纸贵区块链版权登记平台",
            "description": "平台支持用户将版权登记信息上传至区块链网络中，并引入公证处、版权局、知名高校作为版权存证联盟链的存证和监督节点，保证随时可出具国家认证的具有司法效力的公证证明，并在遭遇侵权时作为法院能够采信的侵权证据。纸贵区块链版权登记平台被可信区块链评为2018年可信区块链峰会十大应用案例之一。截止到2018年10月，平台登记版权数量超过85万条，覆盖音、视、图、文等多种形式的存证类型。",
            "icon": "resource/支持多种数据存证.svg"
          },
          {
            "title": "西安城墙国际马拉松赛",
            "description": "2018年4月21日，西安城墙马作为全球首个“区块链+”马拉松赛事，以纸贵联盟链（Zig-Ledger）作为底层技术支撑。通过后台技术对接，将每位参赛者的姓名、赛号、项目、成绩、排名等比赛数据，同步传输至区块链上。参赛者们赛后可下载属于自己的区块链登记证书。记录在区块链上的比赛数据将永久可信、不可篡改。并通过加盖时间戳、使用UTC全球通用协调时间，来确保每位参赛者的成绩真实可靠。",
            "icon": "resource/支持多种数据存证.svg"
          }
        ],
        "category": "case",
        "column": 1
      }
    }
  ],
  "order": 4,
  "createTime": "2018-12-15T15:03:00.255Z",
  "updateTime": "2019-01-03T07:24:20.541Z"
}